.menu4 li:after {
    content: "";
    position: absolute;
    display: block;
    border: 2px solid #fff;
    width: 100%;
    height: 0%;
    transition: all 0.5s ease;
    top: 100%;
  }
  
  .menu4 li:hover::after {
    width: 100%;
    top: 0;
  }